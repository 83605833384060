import React from 'react';
import ToolROISection from '../../Tools/ROICalculator/Section';

const ContentfulTool = (props) => {
  if (props?.internalName === 'ROI Calculator') {
    return <ToolROISection {...props} />;
  }

  return null;
};

export default ContentfulTool;
